<!-- props -->
/**
 * v-model/value Boolean 是否显示 （必填）
 * shareParams Object 分享详情 （必填）
 * shareItem Array 分享面板的选项 （默认：['friend', 'message', 'create'] ）
 * center Boolean 选项是否居中 （默认 true）
 */
<!-- events -->
/**
 * close 关闭回调
 * share-success 分享成功回调
 */
<!-- shareItem 可选项 详见下面 data.itemList-->
<!-- 约定shareItem数组中若包含'save'（即保存图片的意思） 默认是直接分享海报的形式 -->

<template>
  <div class="cl-share-component">
    <van-popup v-model="show" round :overlay-style="{zIndex: 1000}" :overlay="showCover"  position="bottom" :close-on-click-overlay="true" @click-overlay="closeConfirm" :lock-scroll="true">
      <div class="c-share" v-if="!miniSharePoster">

<!--        <slot name="logo">-->
<!--          <img src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/cl_wx/img/common/share_bg_logo.png" class="share-img" v-show="isLogoShow" />-->
<!--        </slot>-->

        <!-- <div class="cs-title">分享到</div> -->
        <div v-if="!isSharePoster" :class="['cs-list',{'share-center': center}]" :style="shareStyle">
          <div class="cl-img" v-for="(item) in shareItems" :key="item.key"  @click="shareFn(item)">
              <img :src="item.img" alt="" />
              <div>{{ item.text }}</div>
            </div>
        </div>
        <div v-if="isSharePoster && onlySave" :class="['cs-list',{'share-center': center}]" :style="shareStyle">
          <div class="cl-img"  @click="shareFn(posterItemList[2])">
              <img v-if="posterItemList[2].img" :src="posterItemList[2].img" />
              <div>{{ posterItemList[2].text }}</div>
          </div>
        </div>
        <div v-else-if="isSharePoster" :class="['cs-list',{'share-center': center}]" :style="shareStyle">
          <div class="cl-img" v-for="(item) in posterItems" :key="item.key"  @click="shareFn(item)">
              <img v-if="item.img" :src="item.img" />
              <div>{{ item.text }}</div>
          </div>
        </div>
        <div class="cs-cancle-btn" @click="closeConfirm()">取消</div>
      </div>
    </van-popup>
    <!-- 生成海报的弹窗 -->
    <div class="html-2-canvas" v-show="isSharePoster">
      <!--小程序分享海报时提示-->
      <div v-if="miniSharePoster" class="mini-share-poster-text">
          长按海报可分享给好友~
          <img @click="closeConfirm()" class="result-close" src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/double11-2021/result-close.png" alt="">
      </div>
      <div class="canvas-box" ref="createDom" :style="miniSharePoster?'padding-bottom:0;padding-top:6.67vw;':''">
        <img class="create-img" :style="`width: ${canvasWidth}`" v-if="params.image" :src="params.image" alt="">
        <slot name="createDom" v-else></slot>
      </div>
    </div>
    <!-- 微信、小程序右上角分享提示 -->
    <van-overlay :show="isShareWx" :z-index="1500" @click="isShareWx = false">
      <div class="tip-box" @click.stop>
        <img src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/anniversary2021/share-mini.png" class="tip-img"/>
      </div>
    </van-overlay>

    <van-overlay :show="isShareImgWx" :z-index="1400" @click="isShareImgWx = false"></van-overlay>

    <next-loading :zIndex="2500" :loadStatus="nextLoadingOptions.loadStatus" :loadingTxt="nextLoadingOptions.loadingTxt"></next-loading>
  </div>
</template>
<script>
import { webAppShare, saveCreateImg, appType } from '@/lib/appMethod'
import nextLoading from "@/components/loading/loading";
import html2canvas from 'html2canvas';

let appTypeStr = appType()

export default {
  components:{
    nextLoading
  },
  props: {
    // 动态分享列表 详见下面 data.itemList
    shareItem: {
      type: Array,
      default: () => {
        return ['minifriend', 'postermessage', 'save']
      }
    },
    reload: { // 可重复生成图片A
      type: Boolean,
      default: false
    },
    // 分享按钮布局，true:居中，false不居中
    center: {
      type: Boolean,
      default: true
    },
    // 分享海报时是否只有保存按钮，教练端暂无分享海报到好友功能
    onlySave: {
      type: Boolean,
      default: false
    },
    // 自定义的分享样式
    shareStyle: {
      type: Object,
      default: () => {},
    },
    // 分享参数，title：标题，content：分享内容，image：图片地址，url：链接url, miniImage:小程序卡片分享图片
    shareParams: {
      type: Object,
      default: null
    },
    // 是否显示
    value: {
      type: Boolean,
      default: false
    },
    // 是否显示背景
    showCover: {
      type: Boolean,
      default: true
    },
    // 小程序分享海报，隐藏底部按钮
    miniSharePoster: {
        type: Boolean,
        default: false
    },
    scale:{
        type: Function,
        default: ()=>window.devicePixelRatio
    }
  },
  data() {
    return {
      // 传进来的值
      show: false, // 弹窗是否显示
      isLogoShow: true, // 是否显示超鹿logo

      nextLoadingOptions: {
        loadStatus: false,
        loadingTxt: "海报生成中...",
      },
      // 菜单列表
      itemList: [
        {
          scene: 0,
          img: 'https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/cl_wx/img/common/001.png',
          text: "微信好友",
          type: '1',
          key: 'friend'
        },
        {
          scene: 1,
          img: 'https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/cl_wx/img/common/002.png',
          text: "朋友圈",
          type: '1',
          key: 'message'
        },
        {
          scene: '',
          img: 'https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/cl_wx/img/common/003.png',
          text: "生成海报",
          type: '',
          key: 'create'
        },
        {
          scene: 0,
          img: 'https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/cl_wx/img/common/001.png',
          text: "微信好友",
          type: '3',
          key: 'minifriend'
        },
        {
          scene: 1,
          img: 'https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/cl_wx/img/common/002.png',
          text: "朋友圈",
          type: '3',
          key: 'minimessage'
        },
      ],

      isSharePoster: false, // 是否是 分享海报
      isShareWx: false,
      isShareImgWx: false,
      posterItemList: [ // 当分享海报时展示
        {
          scene: 0,
          img: 'https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/cl_wx/img/common/001.png',
          text: "微信好友",
          type: '2',
          key: 'posterfriend'
        },
        {
          scene: 1,
          img: 'https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/cl_wx/img/common/002.png',
          text: "朋友圈",
          type: '2',
          key: 'postermessage'
        },
        {
          scene: '',
          img: 'https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/cl_wx/img/common/005.png',
          text: "保存",
          type: '',
          key: 'save'
        },
        {
          scene: 0,
          img: 'https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/cl_wx/img/common/001.png',
          text: "微信好友",
          type: '3',
          key: 'minifriend'
        },
        {
          scene: 1,
          img: 'https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/cl_wx/img/common/002.png',
          text: "朋友圈",
          type: '3',
          key: 'minimessage'
        },
      ],
      params: { // 分享的信息
        url: '',
        image: '',
      },
      canvasWidth: '100%'
    };
  },
  computed: {
    shareItems() {
      let items = []
      this.shareItem.map((item) => {
        this.itemList.map((jtem) => {
          if (jtem.key === item) {
            items.push(jtem)
          }
        })
      })
      return items
    },
    posterItems() {
      let items = []
      this.shareItem.map((item) => {
        this.posterItemList.map((jtem) => {
          if (jtem.key === item) {
            items.push(jtem)
          }
        })
        if(item === 'create'){
          items.push(this.posterItemList[2])
        }
      })
      console.log('hey');
      return items
    }
  },

  created() {
    this.load();
  },
  watch: {
    value(val) { // 显示隐藏
      this.show = val
      if (this.show) {
        this.isLogoShow = true

        // 包含save，表示直接弹起海报的界面
        if (this.shareItem.includes('save')) {
          this.initPoster()
          this.isLogoShow = false
        }
      }else{
          this.isSharePoster = false
      }

    },
    shareParams: {
      handler(val) {
        this.params = JSON.parse(JSON.stringify(this.shareParams))
      },
      deep: true
    }
  },
  methods: {
    load() {
      this.params = this.shareParams ? JSON.parse(JSON.stringify(this.shareParams)) : {}
    },

    // 点击关闭（取消）窗口
    closeConfirm() {
      this.isSharePoster = false
      if (this.reload) this.params.image = ''
      this.$emit('close');
    },
    // 点击分享
    shareFn(item) {
    //   if (!this.params.image) {
    //     return
    //   }
      if (!this.params.url) {
        this.tips('分享参数不能为空')
        return
      }

      let params = {
        scene: item.scene,
        ...this.params
      }
      switch (item.key) {
        case 'friend': // 分享链接给好友

          if (['wx', 'mini'].includes(appTypeStr)) {
            // 提示 点击右上角
            this.isShareWx = true
          } else {
            webAppShare(item.type, params)
          }

          break;
        case 'message': // 分享链接到朋友圈

          if (['wx', 'mini'].includes(appTypeStr)) {
            // 提示 点击右上角
            this.isShareWx = true
          } else {
            webAppShare(item.type, params)
          }

          break;
        case 'create': // 去生成海报
          if (['wx', 'mini'].includes(appTypeStr)) {
            this.show = false
            this.isShareImgWx = true
          }
          this.initPoster();
          break;
        case 'posterfriend': // 分享图片给好友
          webAppShare(item.type, params)
          break;
        case 'postermessage': // 分享图片到朋友圈
          webAppShare(item.type, params)
          break;
        case 'save': // 保存图片
          this.savePosterImg();
          break;
        case 'minifriend': // 分享小程序给好友
          var miniParams = JSON.parse(JSON.stringify(params))
          miniParams.image = miniParams.miniImage
          if (['wx', 'mini'].includes(appTypeStr)) {
            // 提示 点击右上角
            this.isShareWx = true
          } else {
            webAppShare(item.type, miniParams)
          }
          break;
        case 'minimessage': // 分享小程序到朋友圈（暂不可用）

          break;
      }
      if (item.key !== 'create') {
        this.shareSuccess(item.key)
      }
      // 点击分享按钮  埋点使用
      this.$emit('click-share-item', item.key);
    },

    // 海报生成
    initPoster() {
      this.isSharePoster = true
      this.isLogoShow = false
      if (!this.params.image || this.reload) {
        this.nextLoadingOptions.loadStatus = true

        // this.$nextTick(()=>{

        // })

        setTimeout(() => {
          const canvas = this.$refs.createDom.children[0];
          const opts = {
            scale:this.scale(canvas),
            backgroundColor: 'transparent', // transparent
            useCORS: true, // 允许跨域
            height: canvas.offsetHeight,
            width: canvas.offsetWidth,
            // windowWidth: document.body.scrollWidth,
            // windowHeight: document.body.scrollHeight,
          };
          html2canvas(canvas, opts).then((canvas) => {
            let dataURL = canvas.toDataURL('image/png');
            this.params.image = dataURL;
            this.nextLoadingOptions.loadStatus = false
          });
          this.canvasWidth = '80vw'
        }, 800);
      }
    },
    // 保存海报图片
    savePosterImg() {
      saveCreateImg(this.params.image)
    },

    shareSuccess(key) {
      this.isSharePoster = false
      if (this.reload) this.params.image = ''
      this.$emit('share-success', key)
    },

  },
};
</script>
<style lang="less" scoped>
.mini-share-poster-text{
    font-size: 32px;
    font-weight: bold;
    color: white;
    text-align: center;
    padding-top: 62px;
    .result-close{
        position: absolute;
        right: 30px;
        top: 50px;
        width: 60px;
        height: 60px;
    }
}
.c-share {
  width: 100%;
  border-radius: 24px 24px 0px 0px;
  background-color: #fff;
  box-sizing: border-box;
  position: relative;
  padding-bottom: env(safe-area-inset-bottom);
  .share-img {
    width: 180px;
    height: 180px;
    position: absolute;
    right: 10px;
    top: -160px;
  }
  .cs-title {
    padding: 48px 0 0 32px;
    font-size: 32px;
    color: #242831;
    font-weight: bold;
  }
  .cs-list {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-around;
    .cl-img {
      margin-top: 42px;
      font-size: 24px;
      color: #9aa1a9;
      text-align: center;
      margin-left:56px;
      &:first-child {
        margin-left:24px;
      }
      img {
        width: 112px;
        display: block;
        margin: 0 auto;
      }
      div {
        margin-top: 8px;
      }
    }
  }
  .share-center {
    justify-content: center;
    .cl-img {
      flex:1;
      margin-left: 0;
      &:first-child {
        margin-left:0;
      }
    }
  }
  .cs-cancle-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 36px 0;
    font-size: 32px;
    color: #242831;
    box-sizing: border-box;
    border-top: 1px solid #eeeeee;
    margin-top: 48px;
  }
}

.create-img {
  width: 100%;
  display: block;
  margin: auto;
  object-fit: cover;
}
.html-2-canvas {
  font-family: Helvetica, Tahoma, Arial, 'PingFang SC', 'Hiragino Sans GB', 'Heiti SC', STXihei, 'Microsoft YaHei', SimHei;
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1500;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  .canvas-box {
    margin: auto;
    padding: 120px 0 500px;
  }
}
.tip-box {
  position: relative;
  .tip-img {
    position: absolute;
    right: 3.8rem;
    top: 1rem;
    width: 300px;
  }
}
</style>
<style>
.cl-share-component .van-popup{
   overflow-y: inherit !important;
}
</style>
